/* eslint-disable no-useless-escape */
import { mapActions, mapGetters } from 'vuex'
import { currencyFormat, isNotEmptyError, showVueToast } from '@/utils'

const CONFIG = {
  email: 'Email',
  name: 'Name',
  mobilePhone: 'Phone number'
}

export default {
  name: 'event-details',
  data() {
    return {
      visibleContent: true,
      errors: [],
      formPayload: {
        mobilePhone: '',
        email: '',
        name: ''
      },
      isSignUpModalVisible: false,
      isAlertModalVisible: false,
      isPhoneNumberValid: false,
      phoneInputType: 'number',
      studentEmail: null
    }
  },
  components: {
    Cash: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Cash'),
    Calendar: () => import(/* webpackChunkName: "icon" */ '@/components/icons/CalendarNumber'),
    Location: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Location'),
    Time: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Time'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    TextField: () => import(/* webpackChunkName: "button" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    LinkIcon: () => import(/* webpackChunkName: "icon" */ '@/components/icons/LinkIcon'),
    FacebookLogo: () => import(/* webpackChunkName: "icon" */ '@/components/icons/FacebookLogo'),
    Telegram: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Telegram'),
    Twitter: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Twitter'),
    WhatsappGreen: () => import(/* webpackChunkName: "icon" */ '@/components/icons/WhatsappGreen'),
    ShareAndroid: () => import(/* webpackChunkName: "icon" */ '@/components/icons/ShareAndroid')
  },
  filters: {
    currencyFormat
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('program', ['programWebDetail']),
    ...mapGetters('auth', ['isLoggedIn']),
    bilingualContent() {
      if (this.programWebDetail?.content) return this.programWebDetail?.content[0]
    },
    programSlug() {
      return this.$route.params.slug
    },
    mobilePhone() {
      return this.formPayload.mobilePhone
    },
    email() {
      return this.formPayload.email
    },
    name() {
      return this.formPayload.name
    },
    baseDomain() {
      return process.env.VUE_APP_BASE_DOMAIN
    },
    path() {
      return this.$route.fullPath
    },
    url() {
      if (process.env.NODE_ENV === 'beta') return 'http://g2academy-ui-beta.s3-website-ap-southeast-1.amazonaws.com' + this.path
      return 'https://www' + this.baseDomain + this.path
    },
    studentId() {
      return localStorage.getItem('student_id')
    },
    isQuickRegistration() {
      return !!this.$route.query.quickRegistration && this.$route.query.quickRegistration === 'true'
    },
    activeLanguange() {
      return this.$i18n.locale
    },
    utmCampaign() {
      return sessionStorage.getItem('utm_campaign')
    },
    utmSource() {
      return sessionStorage.getItem('utm_source')
    },
    utmMedium() {
      return sessionStorage.getItem('utm_medium')
    }
  },
  async mounted() {
    await this.getEventDetails()
  },
  watch: {
    activeLanguange() {
      this.getEventDetails()
    },
    mobilePhone(value) {
      if (value.length >= 15) {
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }

      if (value.length < 10) {
        this.errors['mobilePhone'] = 'Minimum 10 digits, Maximum 15 digits'
        this.isPhoneNumberValid = false
      } else {
        this.errors['mobilePhone'] = ''
        this.isPhoneNumberValid = true
      }
    },
    email(value) {
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (emailPattern.test(value)) {
        this.errors['email'] = ''
      } else {
        this.errors['email'] = 'Incorrect email format!'
      }
    },
    name(value) {
      if (value !== '') {
        this.errors['name'] = ''
      } else {
        // eslint-disable-next-line quotes
        this.errors['name'] = `name can't be empty!`
      }
    }
  },
  methods: {
    ...mapActions('program', ['getProgramByWebSlug', 'getFormToken']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('auth', ['registerEvent', 'removeUserData']),
    ...mapActions('data', ['setEmailEvent']),
    ...mapActions('student', ['getStudentDetail']),
    getProfile() {
      this.getStudentDetail({ studentId: this.studentId }).then((res) => {
        this.studentEmail = res.email
        if (this.isQuickRegistration) this.showSignUpModal()
      })
    },
    showSignUpModal() {
      if (!this.isLoggedIn) {
        this.toogleSignUpModal()
        return
      }
      this.signUp()
    },
    toogleAlertModal() {
      this.isAlertModalVisible = !this.isAlertModalVisible
    },
    setAction(target) {
      const cleanUserData = new Promise((resolve, reject) => {
        resolve(this.removeUserData())
      })
      cleanUserData.then(() => {
        this.toogleAlertModal()
        if (target === 'register-event') return this.toogleSignUpModal()
        this.$router.push('/login#student')
      })
    },
    async copyLink() {
      try {
        await navigator.clipboard.writeText('Check this out: ' + this.url)
        await showVueToast('Copy link successful!', 'success', 2000)
      } catch (err) {
        await showVueToast('Copy link failed!', 'error', 2000)
      }
    },
    save() {
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (!this.emptyValidation()) return
      if (!this.isPhoneNumberValid) this.errors['mobilePhone'] = 'Incorrect phone number length!'
      if (!emailPattern.test(this.formPayload.email)) {
        this.errors['email'] = 'Incorrect email format!'
        return
      }
      this.signUp()
    },
    signUp() {
      this.showLoading()

      let requestBody = {}
      if (this.isLoggedIn) {
        requestBody = {
          coreClassId: this.programWebDetail.id,
          email: this.studentEmail
        }
      } else {
        requestBody = {
          coreClassId: this.programWebDetail.id,
          email: this.formPayload.email,
          name: this.formPayload.name,
          mobilePhone: this.formPayload.mobilePhone
        }
      }

      const newReqBody = {
        ...requestBody,
        ...(this.utmSource && { utmSource: this.utmSource }),
        ...(this.utmCampaign && { utmCampaign: this.utmCampaign }),
        ...(this.utmMedium && { utmMedium: this.utmMedium })
      }

      // eslint-disable-next-line no-unused-expressions
      this.getFormToken().then((response) => {
        const formToken = response.data.data
        this.registerEvent({ formToken: formToken, requestBody: newReqBody }).then(
          () => {
            this.hideLoading()
            this.setEmailEvent({ value: this.formPayload.email })
            this.isSignUpModalVisible = false
            this.$router.push({ path: `/event/${this.programSlug}/thank-you` })
          },
          (error) => {
            this.hideLoading()
            const errorMessage = error.response.data.errors.error
            if (errorMessage.includes('AlreadyEnrollLmsClassException')) {
              return showVueToast('Email already registered!', 'error', 3000)
            }
            if (errorMessage.includes('Email is required')) {
              return this.toogleAlertModal()
            }
            showVueToast('Your registration failed, immediately contact the admin!', 'error', 3000)
          }
        )
      })
    },

    toogleSignUpModal() {
      this.errors = []
      this.formPayload = {
        mobilePhone: '',
        email: '',
        name: ''
      }
      this.isSignUpModalVisible = !this.isSignUpModalVisible
    },
    emptyValidation() {
      const error = isNotEmptyError(this.formPayload)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    },
    getEventDetails() {
      if (!this.programSlug) return this.$router.push({ name: 'page-not-found' })
      if (this.programSlug === 'open-day') return this.$router.push({ name: 'Open Day' })
      this.hideLoading()
      this.visibleContent = false
      const params = {
        lang: this.activeLanguange
      }
      this.getProgramByWebSlug({
        slug: this.programSlug,
        params
      }).then(
        (res) => {
          if (this.isLoggedIn) {
            this.getProfile()
          } else {
            if (this.isQuickRegistration) this.showSignUpModal()
          }
          if (res.data.code === 500) return this.$router.push({ name: 'page-not-found' })
          if (this.programWebDetail.layoutType === 'CLASS_LAYOUT') return this.$router.push('/')
          this.visibleContent = true
          const backgroundImage = this.programWebDetail.url2 ? this.programWebDetail.url2 : this.programWebDetail.url1
          setTimeout(() => {
            const hero = document.getElementById('hero-background-event')
            hero.style.backgroundImage = `url('${backgroundImage}')`
            hero.style.filter = 'blur(8px)'
          }, 500)
        },
        () => {
          this.showLoading()
        }
      )
    }
  }
}
